define("discourse/plugins/discourse-mycad2-slider/discourse/templates/connectors/above-main-container/mycad2-slider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Mycad2Slider />
  
  */
  {
    "id": "dMNvTa+P",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"]],[],false,[\"mycad2-slider\"]]",
    "moduleName": "discourse/plugins/discourse-mycad2-slider/discourse/templates/connectors/above-main-container/mycad2-slider.hbs",
    "isStrictMode": false
  });
});