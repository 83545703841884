define("discourse/plugins/discourse-mycad2-slider/initializers/discourse2-mycad-slider", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'discourse-mycad2-slider',
    initialize(container) {
      (0, _pluginApi.withPluginApi)('0.12.1', api => {
        let left_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-left" d="M29,46,9,26,29,6" transform="translate(-8 -4.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
        let right_icon = '<svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="42.828" viewBox="0 0 22.414 42.828"><path id="chevron-right" d="M29,46,9,26,29,6" transform="translate(30.414 47.414) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/></svg>';
        let siteSettings = container.lookup('site-settings:main');
        $(document).ajaxSend(function (event, xhr, settings) {
          settings.xhrFields = {
            withCredentials: true
          };
        });
        (0, _loadScript.default)('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js').then(() => {
          init_mycad2_slider();
        });
        function init_mycad2_slider() {
          // Mapping des langues
          const languageMapping = {
            pl_PL: 'pl'
          };
          const lang = languageMapping[I18n.locale] || I18n.locale;
          const mycad2SlideAPI = `${siteSettings.mycad2_api_host}/content/discourse-carousel?locale=${lang}`;
          let slideHost = siteSettings.mycad2_logged_host;
          if (!Discourse.User.current()) {
            slideHost = siteSettings.mycad2_anonymous_host;
          }
          $.getJSON(mycad2SlideAPI, function (slide_data) {
            $.each(slide_data, function (i, item) {
              let imageUrl = item.original || item.image;
              if (!imageUrl) return;
              let linkHost = item.image_Link.startsWith("http") || item.image_Link.startsWith("www") ? item.image_Link : `${slideHost}${item.image_Link}`;
              $("#mycad2-slider-images").hide().append("<div id='slide-" + i + "'></div>");
              $("<a></a>").attr("href", linkHost).attr("title", item.title).attr('target', '_blank').appendTo("#mycad2-slider-images div#slide-" + i);
              $("<img>").attr("src", imageUrl).appendTo("#mycad2-slider-images div#slide-" + i + " a");
            });
            setTimeout(() => {
              $('.js-banner-slick').slick({
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                dots: true,
                adaptiveHeight: true,
                autoplay: true,
                autoplaySpeed: 5000,
                prevArrow: '<span class="slick-prev">' + left_icon + '</span>',
                nextArrow: '<span class="slick-next">' + right_icon + '</span>'
              });
              $('#mycad2-slider-images').show();
              $('#mycad2-slider-loading').hide();
            }, 600);
            if (slide_data == null) {
              $('.mycad2-slider').hide();
            }
          }).fail(function () {
            console.log("Error while getting mycad2 slider!");
            $('.mycad2-slider').hide();
          });
        }
      });
    }
  };
});